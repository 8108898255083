
import Vue from "vue";
import { DateTime } from "luxon";
import notification from "@/services/notificationService";

export default Vue.extend({
  name: "VehicleList",
  data() {
    return {
      filter: "All" as "All" | "Archived" | "Active",
      downloadPreview: false
    };
  },
  computed: {
    selectedTenant() {
      return this.$store.state.selectedTenant;
    },
    vehicleExportFile() {
      return this.$store.state.vehicleExportFile;
    }
  },
  watch: {
    filter: function() {
      this.downloadPreview = false;
      this.$store.dispatch("setVehicleExportFile", undefined);
    },
    selectedTenant: function() {
      this.downloadPreview = false;
      this.$store.dispatch("setVehicleExportFile", undefined);
    },
    vehicleExportFile: function() {
      if (this.vehicleExportFile) {
        // if export file exist download it
        // creating csv file link from blob
        // and simulating click to download
        const url = URL.createObjectURL(
          new Blob([this.vehicleExportFile], {
            type: "text/csv"
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.filter +
            " vehicle list - " +
            DateTime.fromJSDate(new Date()).toFormat("dd LLL yyyy") +
            ".csv"
        );
        document.body.appendChild(link);
        link.click();
      }
    }
  },
  methods: {
    generateVehicleExportFile() {
      this.$store
        .dispatch("getVehicleExportFile", {
          selectedTenant: this.selectedTenant,
          filter: this.filter
        })
        .then(response => {
          this.downloadPreview = true;
        });
    }
  }
});
